import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '..';
import React from 'react';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../components/InputFormField';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  currentUserEmail: string;
}

export function EmailRow(props: PropTypes) {
  return (
    <div className='row mb-3'>
      <Col sm={12}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('email', { required: true })}
          fieldName={'Email'}
          value={props.currentUserEmail}
          disabled={true}
        />
      </Col>
    </div>
  );
}
