import React from 'react';
import { LoadingSpinnerIcon } from '../../../../../../../components/LoadingSpinnerIcon';

export function EmailIntegrationFetchState() {
  return (
    <div className='d-flex fs-6 align-items-center'>
      <LoadingSpinnerIcon className='text-blue me-2' size='lg' />
      Fetching email Integration ...
    </div>
  );
}
