import React from 'react';
import { SpanTooltip } from '../../../components/SpanTooltip';

export function PasswordRequirementsList() {
  return (
    <div className='text-dark-200 fw-regular fs-6'>
      <div>The password must include:</div>
      <ul className='ps-3'>
        <li>An uppercase letter;</li>
        <li>A lower case letter;</li>
        <li>A number;</li>
        <li>
          An allowed special character{' '}
          <SpanTooltip
            text={<i className='bi bi-info-circle ms-1 text-info'></i>}
            tooltipText={
              'Allowed special characters: #, ?, !, @, $,?%, ^, &, *, -, . (dot), _, , (comma), +, :, ~.'
            }
            trigger='hover'
          />
        </li>
      </ul>
    </div>
  );
}
