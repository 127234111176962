import React, { useState } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import ScheduleInterviewPage, { SchedulingSettings } from '../index';
import { User } from '../../../../../entities/User';
import candidateSchedulingImage from '../../../../../images/candidate-experience-mailer-new-survey.png';
import {
  InterviewDatePickerFooter,
  UpsertSelfScheduleInterviewPropTypes,
} from '../InterviewDatePicker/InterviewDatePickerFooter';
import {
  BulkSelfScheduleResponse,
  InterviewCandidateUserService,
} from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import {
  appendCurrentRedirectUri,
  getRedirectUriQueryParam,
} from '../../../../../utils/url';
import { ShareTeamAvailabilityFooter } from '../ShareTeamAvailabilityFooter';
import { getApplicationIdsToBulkSchedule } from '../../../../../utils/applicant_tracking/bulkScheduleInterview';
import { redirectWithAlert } from '../../../../../services/FlashService';
import { getLabelForCount } from '../../../../../utils/grammar';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  currentUser: User;
  availableParticipants: User[];
  jobId: number;
  interviewStage: InterviewStage;
  meetingProviders: VcsProvider[];
  defaultMeetingProvider: VcsProvider;
}

function buildAlertMessage(response: BulkSelfScheduleResponse) {
  if (response.recordsCreated == 0) {
    return `The self-scheduling link was not sent to any candidate.`;
  }

  return `The self-scheduling link has been successfully sent to ${getLabelForCount(
    'Candidate',
    response.recordsCreated,
  )}.`;
}

export default function BulkScheduleInterview(props: PropTypes) {
  const [schedulingSettings, setSchedulingSettings] =
    useState<SchedulingSettings>(null);

  const applicationIds = getApplicationIdsToBulkSchedule();

  const onConfirm = async (data: UpsertSelfScheduleInterviewPropTypes) => {
    try {
      const response =
        await InterviewCandidateUserService.bulkCreateSelfSchedule(
          applicationIds,
          props.jobId,
          props.interviewStage.id,
          data.interviewerUserIds,
          data.meetingProvider,
          data.durationMinutes,
          data.dateRange,
          data.scheduleFromHours,
          data.timezone,
          data.meetingOrganizer.id,
        );

      redirectWithAlert(
        getRedirectUriQueryParam() ?? '/candidates?tab=all',
        buildAlertMessage(response),
      );
    } catch (e) {
      window.location.href = appendCurrentRedirectUri(
        `/applicant_tracking/interview_candidate_users/schedule/error`,
      );
    }
  };

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.currentUser}
      applicationIds={applicationIds}
      title={'Let the candidate schedule the interview'}
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      participants={[props.currentUser]}
      availableParticipants={props.availableParticipants}
      interviewStage={props.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.defaultMeetingProvider}
      customImage={candidateSchedulingImage}
      onConfirm={(_data) => null}
      candidateView={false}
      selfScheduleView={true}
      allowChangingMeetingOrganizer={true}
      allowIgnoreParticipantCalendar={false}
      setSchedulingSettings={setSchedulingSettings}
      interviewDatePickerFooter={
        <InterviewDatePickerFooter
          buttonText='Confirm Availability'
          description={
            <ShareTeamAvailabilityFooter
              schedulingSettings={schedulingSettings}
            />
          }
          onConfirm={onConfirm}
        />
      }
    />
  );
}
