import React from 'react';
import { User } from '../../../../entities/User';
import {
  ApplicationFeed,
  ApplicationFeedEmail,
} from '../../../../entities/ApplicationFeed';
import { CommentList } from './CommentList';
import { CommentInput } from './CommentInput';
import { SendEmailModal } from '../../CandidateActions/SendEmailModal';
import { Application } from '../../../../entities/Application';
import { CommentButton } from './CommentList/CommentButton';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  entry: ApplicationFeed;
  currentUser: User;
  emailAccountIntegration?: AccountIntegration;
  setAlert: (data: AlertObject) => void;
  reloadApplication: (state: boolean) => void;
}

export function CommentSection(props: PropTypes) {
  const [showNewCommentBox, setShowNewCommentBox] =
    React.useState<boolean>(false);
  const [showReplyToEmail, setShowReplyToEmail] =
    React.useState<boolean>(false);

  const handleNewCommentClose = (state: boolean) => {
    if (state) {
      props.reloadApplication(state);
    }
    setShowNewCommentBox(false);
  };

  const handleReplyEmailClose = (state: boolean) => {
    if (state) {
      props.reloadApplication(state);
    }
    setShowReplyToEmail(false);
  };

  return (
    <>
      {showReplyToEmail && props.emailAccountIntegration && (
        <SendEmailModal
          applicationIds={[props.application.id]}
          isOpen={showReplyToEmail}
          hasEmailConnected={props.currentUser.hasEmailConnected}
          toEmail={props.application.candidate.email}
          replyToEmail={(props.entry as ApplicationFeedEmail)?.email}
          onClose={handleReplyEmailClose}
          setAlert={props.setAlert}
        />
      )}
      {showNewCommentBox && (
        <div className='mt-3'>
          <CommentInput
            entry={props.entry}
            currentUser={props.currentUser}
            onClose={handleNewCommentClose}
          />
        </div>
      )}
      {props.entry.comments.length > 0 ? (
        <CommentList
          applicationFeed={props.entry}
          currentUser={props.currentUser}
          showNewCommentBox={showNewCommentBox}
          setShowNewCommentBox={setShowNewCommentBox}
          setShowReplyToEmail={setShowReplyToEmail}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
        />
      ) : (
        <div className='mt-2h'>
          <CommentButton
            showNewCommentBox={showNewCommentBox}
            entryType={props.entry.entryType}
            setShowNewCommentBox={setShowNewCommentBox}
            setShowReplyToEmail={setShowReplyToEmail}
          />
        </div>
      )}
    </>
  );
}
