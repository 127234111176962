import React from 'react';
import { Button } from 'reactstrap';

interface PropTypes {
  handleSignOut: () => void;
  isFormValid: string | boolean;
}

export function InvitationButtons(props: PropTypes) {
  return (
    <div className='d-flex justify-content-center'>
      <Button
        color='danger'
        className='me-2'
        onClick={() => props.handleSignOut()}
        size={'md'}
      >
        Cancel
      </Button>
      <Button
        color='primary'
        className='me-2'
        type='submit'
        size={'md'}
        disabled={!props.isFormValid}
      >
        Sign Up
      </Button>
    </div>
  );
}
