import React from 'react';
import { EmptyState } from '../../../../../../components/EmptyState';
import { FeedbackFilters } from '../../../Filters';

export function EmptyScorecards(props: { filters: FeedbackFilters }) {
  if (
    props.filters?.status ||
    props.filters?.jobStages?.length ||
    props.filters?.hiringMembers?.length
  ) {
    return (
      <EmptyState
        classNames='my-6 py-6'
        title='No feedback found'
        text='Please try a different combination of filters.'
      />
    );
  }

  if (props.filters?.feedbackType === 'candidate_rating') {
    return (
      <EmptyState
        classNames='my-6 py-6'
        title='No candidate rating filled in yet'
        text='Looks like no candidate rating have been completed yet. Add now or check back later.'
      />
    );
  }

  return (
    <EmptyState
      classNames='my-6 py-6'
      title='No scorecards filled in yet'
      text='There are no scorecards to fill in or none have been completed yet. Check back later.'
    />
  );
}
