import React from 'react';
import { StarRating } from '../../../../../../../../components/StarRating';
import { calcScorecardAverage } from '../../../../../../../../utils/applicant_tracking/calcScorecardAverage';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';
import styles from './styles.module.scss';

interface PropTypes {
  scorecard: Scorecard;
}

export function AverageRating(props: PropTypes) {
  const averageScore =
    Math.round(calcScorecardAverage(props.scorecard) * 10) / 10;

  const shouldDisplayScore = averageScore !== 0 && !Number.isNaN(averageScore);

  return (
    <>
      {shouldDisplayScore && (
        <div className='d-flex fs-6 align-items-center'>
          <StarRating
            id='average'
            editable={false}
            rating={averageScore}
            fillColorClass='text-rating-color'
          />
          <span className={styles['rating-text']}>
            <span className='fw-bold mx-2'>{averageScore}</span>{' '}
            <span className='text-dark-200'>AVG Attribute Rating</span>
          </span>
        </div>
      )}
    </>
  );
}
