import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col } from 'reactstrap';
import { FormValues } from '..';
import { InputFormField } from '../../../components/InputFormField';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  invalidInput: boolean;
}

export function PasswordRow(props: PropTypes) {
  return (
    <>
      <div className='row mb-3'>
        <Col sm={12}>
          <InputFormField
            type={'password'}
            registerReturn={props.register('password', {
              required: true,
            })}
            fieldName={'Password'}
            placeholder={'Insert password'}
            isRequired={true}
            minimumCharacters={8}
            invalidInput={props.invalidInput}
          />
        </Col>
      </div>
      <div className='row mb-3'>
        <Col sm={12}>
          <InputFormField
            type={'password'}
            registerReturn={props.register('password_confirmation', {
              required: true,
            })}
            fieldName={'Confirm Password'}
            placeholder={'Confirm password'}
            isRequired={true}
            minimumCharacters={8}
            invalidInput={props.invalidInput}
          />
        </Col>
      </div>
    </>
  );
}
