import React, { useState } from 'react';
import { HeaderRow } from '../HeaderRow';
import { EmailTemplate } from '../../../../../../../entities/EmailTemplate';
import { SelectOption } from '../../../../../../../components/Select';
import { AsyncSelect } from '../../../../../../../components/Select/async';
import { EmailTemplateService } from '../../../../../../../services/EmailTemplateService';
import { EmailTemplateList } from '../../../../../../../entities/EmailTemplateList';

interface PropTypes {
  emailTemplate?: EmailTemplate;
  setEmailTemplate: (emailTemplate: EmailTemplate) => void;
}

async function filterEmailTemplates(
  emailTemplates: Promise<EmailTemplateList>,
  q: string,
): Promise<SelectOption[]> {
  return emailTemplates.then((list) =>
    list.emailTemplates
      .filter((template) =>
        template.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.name,
      })),
  );
}

export function EmailTemplates(props: PropTypes) {
  const [options] = useState(EmailTemplateService.list());

  async function handleTemplateChange(option: SelectOption) {
    return options
      .then((i) =>
        i.emailTemplates.find((x) => x.id.toString() === option.value),
      )
      .then((selected) => props.setEmailTemplate(selected));
  }

  function selectedOption(): SelectOption {
    if (props.emailTemplate == null) return null;

    return {
      value: props.emailTemplate.id.toString(),
      label: props.emailTemplate.name,
      selected: true,
    };
  }

  return (
    <HeaderRow label='Template'>
      <AsyncSelect
        loadOptions={(q: string) => filterEmailTemplates(options, q)}
        maxMenuHeight={250}
        selected={selectedOption()}
        onChange={handleTemplateChange}
      />
    </HeaderRow>
  );
}
