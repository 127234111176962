import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserInvitationService } from '../../services/UserInvitationService';
import { HeaderMessageCol } from './HeaderMessageCol';
import { NameAndLastNameRow } from './NameAndLastNameRow';
import { EmailRow } from './EmailRow';
import { PasswordRow } from './PasswordRow';
import { PasswordRequirementsList } from './PasswordRequirementsList';
import { InvitationButtons } from './InvitationButtons';
import { Row } from 'reactstrap';
import { Card } from '../../components/Card';
import { Alert, AlertType } from '../../components/Alert';

interface PropTypes {
  inviterUserName: string;
  currentUserFirstName: string;
  currentUserLastName: string;
  currentUserEmail: string;
  organizationName: string;
  allowPassword: boolean;
}

export interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export default function UserInvitationPage(props: PropTypes) {
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [hasPasswordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { register, handleSubmit, watch, resetField } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      first_name: props.currentUserFirstName,
      last_name: props.currentUserLastName,
      email: props.currentUserEmail,
    },
  });

  function handlePasswordError(message: string) {
    setErrorMessage(message);
    resetField('password');
    resetField('password_confirmation');
    setPasswordValidationFailed(true);
    setAlertActive(true);
  }

  const firstName = watch('first_name');
  const lastName = watch('last_name');
  const email = watch('email');
  const password = props.allowPassword ? watch('password') : '';
  const passwordConfirmation = props.allowPassword
    ? watch('password_confirmation')
    : '';

  const isFormValid =
    firstName &&
    lastName &&
    email &&
    (!props.allowPassword || (password && passwordConfirmation));

  const onSubmit = async (data: FormValues) => {
    const { first_name, last_name, password, password_confirmation } = data;

    if (password !== password_confirmation) {
      handlePasswordError(
        'The passwords you entered do not match. Please double-check and try again.',
      );
      return;
    }

    try {
      await UserInvitationService.patch(
        first_name,
        last_name,
        password!,
        password_confirmation!,
      );
      window.location.href = '/';
    } catch (e) {
      if (e.status === 422) {
        handlePasswordError(
          'Password does not meet the requirements. Please enter a valid password.',
        );
      } else {
        handlePasswordError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleSignOut = async () => {
    await UserInvitationService.destroy();
    window.location.href = '/';
  };

  return (
    <>
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => setAlertActive(false)}
          autoClearTimeout={4000}
        >
          {errorMessage}
        </Alert>
      )}
      <div className='row justify-content-center'>
        <div className='col-4 col-md-4'>
          <Card>
            <div className='app-card-header'>
              <Row className='align-items-center gx-3'>
                <HeaderMessageCol
                  inviterUserName={props.inviterUserName}
                  organizationName={props.organizationName}
                />
              </Row>
            </div>
            <div className='app-card-body px-3 w-100'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='item pt-3'>
                  <NameAndLastNameRow
                    register={register}
                    currentUserFirstName={props.currentUserFirstName}
                    currentUserLastName={props.currentUserLastName}
                  />
                  <EmailRow
                    register={register}
                    currentUserEmail={props.currentUserEmail}
                  />
                  {props.allowPassword && (
                    <PasswordRow
                      register={register}
                      invalidInput={hasPasswordValidationFailed}
                    />
                  )}
                  <PasswordRequirementsList />
                </div>
                <InvitationButtons
                  handleSignOut={handleSignOut}
                  isFormValid={isFormValid}
                />
              </form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
