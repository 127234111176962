import React from 'react';
import { longFormatDate } from '../../../../../../../../utils/timeFormat';
import { User } from '../../../../../../../../entities/User';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

function Name(props: { currentUser: User; scorecardUser: User }) {
  return (
    <>
      <span className='fw-semibold text-primary'>
        {props.scorecardUser.name}
      </span>
      {props.scorecardUser.id === props.currentUser.id && (
        <>
          &nbsp;
          <Chip className='fw-semibold' color={ChipColor.Gray}>
            My Own
          </Chip>
        </>
      )}
    </>
  );
}

export function Footer(props: PropTypes) {
  return (
    <div className='d-flex justify-content-end text-dark-200 fs-5 pb-4 mt-3'>
      {!props.viewAllScorecards ? (
        <>Scorecard filled on </>
      ) : (
        <>
          <Name
            currentUser={props.currentUser}
            scorecardUser={props.scorecard.user}
          />
          &nbsp;filled scorecard on{' '}
        </>
      )}
      {longFormatDate(props.scorecard.updatedAt)}
    </div>
  );
}
