import React from 'react';
import { Col } from 'reactstrap';
import { Scorecard } from '../../../../../../entities/applicant_tracking/Scorecard';
import { User } from '../../../../../../entities/User';
import { Section } from '../../../../../../entities/v1/survey_engine/Section';
import { Survey } from '../../../../../../entities/v1/survey_engine/Survey';
import { ScorecardRatingQuestion } from './ScorecardRatingQuestion';

interface PropTypes {
  scorecard: Scorecard;
  section: Section;
  user?: User;
  className?: string;
  fillColorClass?: string;
  readonly?: boolean;
  setScorecard?: (
    value: Scorecard | ((prevScorecard: Scorecard) => Scorecard),
  ) => void;
}

export function RatingSection(props: PropTypes) {
  return (
    <Col xs='12' className='mt-4 pt-2'>
      <ScorecardRatingQuestion
        scorecardId={props.scorecard.id}
        editable={
          !props.readonly && props.user?.id === props.scorecard.user?.id
        }
        survey={props.scorecard.survey}
        section={props.section}
        attributes={props.section.questions}
        setSurvey={(survey: Survey) =>
          props.setScorecard?.((scorecard) => ({ ...scorecard, survey }))
        }
        fillColorClass={props.fillColorClass}
        className={props.className}
      />
    </Col>
  );
}
