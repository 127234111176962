import React from 'react';
import { Footer } from './Footer';
import { User } from '../../../../../../../entities/User';
import { Scorecard } from '../../../../../../../entities/applicant_tracking/Scorecard';
import { ScorecardQuestion } from '../../../../../../applicant_tracking/Scorecards/ScorecardPage/ScorecardBody/ScorecardQuestion';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

export function ScorecardBody(props: PropTypes) {
  return (
    <div className='scorecard-body'>
      {props.scorecard.survey?.sections.map((section) => {
        return (
          <ScorecardQuestion
            {...props}
            key={section.id}
            user={props.currentUser}
            section={section}
            fillColorClass='text-rating-color'
            readonly={true}
          />
        );
      })}
      <Footer
        currentUser={props.currentUser}
        scorecard={props.scorecard}
        viewAllScorecards={props.viewAllScorecards}
      />
    </div>
  );
}
