import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  children: React.ReactNode;
  className?: string;
  color: ChipColor;
  size?: Size;
  truncate?: boolean;
}

export enum ChipColor {
  Blue = 'blue',
  Blue500 = 'blue-500',
  BlueLight = 'light-info',
  Danger = 'danger',
  DangerLight = 'light-danger',
  Gray = 'gray',
  Gray900 = 'gray-900',
  Orange = 'orange',
  OrangeLight = 'light-warning',
  Success = 'success',
  SuccessLight = 'light-success',
  White = 'white',
  Yellow = 'yellow',
  YellowLight = 'yellow-light',
}

function chipSizeClasses(size: Size) {
  switch (size) {
    case 'sm':
      return 'fs-6 px-2 py-1';
    case 'md':
      return 'fs-5 px-2h py-2';
    case 'lg':
      return 'fs-4 px-3 py-2h';
    default:
      return 'fs-6 px-2 py-1';
  }
}

function chipColorClassName(color: ChipColor) {
  const bgColorClassName = `bg-${color}`;

  switch (color) {
    case ChipColor.BlueLight:
    case ChipColor.DangerLight:
    case ChipColor.Gray:
    case ChipColor.Orange:
    case ChipColor.OrangeLight:
    case ChipColor.SuccessLight:
    case ChipColor.White:
    case ChipColor.Yellow:
    case ChipColor.YellowLight:
      return `${bgColorClassName} text-primary`;
    default:
      return `${bgColorClassName} text-white`;
  }
}

export function Chip(props: PropTypes) {
  const truncate = props.truncate ?? false;
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <>
      <small
        className={classNames(
          'badge',
          'rounded-pill',
          'fw-semibold',
          { 'text-truncate': truncate },
          props.className,
          props.size && chipSizeClasses(props.size),
          chipColorClassName(props.color),
        )}
        ref={ref}
      >
        {props.children}
      </small>
      {truncate && (
        <Tooltip
          target={ref}
          isOpen={isTooltipOpen}
          toggle={() => setIsTooltipOpen(!isTooltipOpen)}
        >
          {props.children}
        </Tooltip>
      )}
    </>
  );
}
