import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from '../../../../../components/Avatar';
import { Application } from '../../../../../entities/Application';
import { ApplicationDetails } from '../../../../CandidatePage/ProfileTab/CandidatePanel/ApplicationDetails';
import { StarRating } from '../../../../../components/StarRating';
import { CandidatePageHeaderStatusInfo } from '../../../../CandidatePage/CandidatePageHeader/CandidatePageHeaderStatusInfo';
import { shortFormatDate } from '../../../../../utils/timeFormat';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';

interface PropTypes {
  application: Application;
}

function AvatarAndName(props: PropTypes) {
  return (
    <div className='d-flex fw-bold fs-3 align-items-center mb-3h'>
      <Avatar
        size='xl'
        name={props.application.candidate.name}
        className='me-3h'
      />
      {props.application.candidate.name}
    </div>
  );
}

function RatingAndStatus(props: PropTypes) {
  return (
    <div className='d-flex align-items-center mb-3 justify-content-between'>
      {props.application.rating && (
        <div className='d-flex me-2h'>
          <div className='fs-1 fw-bold me-2'>{props.application.rating}</div>
          <StarRating
            id='rating'
            rating={props.application.rating}
            editable={false}
            fillColorClass='text-rating-color'
          />
        </div>
      )}
      <CandidatePageHeaderStatusInfo
        status={props.application.status}
        classNames={'ms-0'}
      />
    </div>
  );
}

function AppliedAt(props: PropTypes) {
  return (
    <div className='fs-5 text-dark-200 mb-3'>
      Applied at {shortFormatDate(props.application.appliedAt)}
    </div>
  );
}

function JobAndStagePills(props: PropTypes) {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.application.job.name]);

  return (
    <div className='d-flex mb-3h'>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='bottom'
        >
          <div className='text-white'>{props.application.job.name}</div>
        </Tooltip>
      )}
      <Chip
        className={classNames(
          'me-2 fw-semibold text-truncate',
          isOverflowing && 'w-100',
        )}
        color={ChipColor.BlueLight}
      >
        <div ref={ref} className='text-truncate'>
          {props.application.job.name}
        </div>
      </Chip>
      <Chip className='fw-semibold' color={ChipColor.OrangeLight}>
        {props.application.job_stage_name}
      </Chip>
    </div>
  );
}

export function CandidatePanel(props: PropTypes) {
  return (
    <div className='mx-6h my-4'>
      <AvatarAndName {...props} />
      <RatingAndStatus {...props} />
      <AppliedAt {...props} />
      <JobAndStagePills {...props} />
      <ApplicationDetails {...props} hideCV />
    </div>
  );
}
