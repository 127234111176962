import React, { useState } from 'react';
import { BULK_ACTION_ERROR_MESSAGE } from '..';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { SendEmailModal } from '../../../../../../CandidatePage/CandidateActions/SendEmailModal';
import { BulkActionButton } from '../BulkActionButton';
import { User } from '../../../../../../../entities/User';
import {
  checkIfAnySelectedCandidatesAreRedacted,
  checkIfSelectedCandidatesHaveSameStatus,
} from '../utils';

interface PropTypes {
  candidates: ListCandidate[];
  currentUser: User;
  selectedCandidates: ListCandidate[];
  selectionCountLabel: string;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

export function SendEmailButton(props: PropTypes) {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const actionDisabled =
    props.selectedCandidates.length === 0 ||
    !checkIfSelectedCandidatesHaveSameStatus(props.selectedCandidates) ||
    checkIfAnySelectedCandidatesAreRedacted(props.selectedCandidates);
  const toolTipText = actionDisabled
    ? 'Bulk e-mail sending is only available for candidates that share the same status and are not-redacted'
    : 'Send Email';

  const handleEmailModalClose = (succeeded: boolean) => {
    if (succeeded === true) {
      props.reloadPage();
      props.setAlert({
        message: (
          <span>
            The email was successfully sent to{' '}
            <span className='fw-bold'>{props.selectionCountLabel}</span>
          </span>
        ),
        type: AlertType.Success,
      });
    } else if (succeeded === false) {
      props.setAlert({
        message: BULK_ACTION_ERROR_MESSAGE,
        type: AlertType.Danger,
      });
    }

    setIsEmailModalOpen(false);
  };

  return (
    <>
      <BulkActionButton
        iconName='bi-envelope'
        toolTipText={toolTipText}
        disabled={actionDisabled}
        onClick={() => setIsEmailModalOpen(true)}
      />
      {isEmailModalOpen && (
        <SendEmailModal
          hasEmailConnected={props.currentUser.hasEmailConnected}
          toEmail={
            props.selectedCandidates.length == 1 &&
            props.selectedCandidates[0].email
          }
          applicationIds={props.selectedCandidates.map((c) => c.application.id)}
          isOpen={isEmailModalOpen}
          onClose={handleEmailModalClose}
        />
      )}
    </>
  );
}
