import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '..';
import React from 'react';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../components/InputFormField';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  currentUserFirstName: string;
  currentUserLastName: string;
}

export function NameAndLastNameRow(props: PropTypes) {
  return (
    <div className='row mb-3'>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('first_name', {
            required: true,
          })}
          fieldName={'First Name'}
          placeholder={props.currentUserFirstName}
          isRequired={true}
        />
      </Col>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('last_name', { required: true })}
          fieldName={'Last Name'}
          placeholder={props.currentUserLastName}
          isRequired={true}
        />
      </Col>
    </div>
  );
}
