import React, { useState } from 'react';
import { Application } from '../../../entities/Application';
import { Col, Row } from 'reactstrap';
import { User } from '../../../entities/User';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { AlertObject } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';
import { FeedbackList } from './FeedbackList';
import { FeedbackFilters, Filters } from './Filters';
import { AddFeedback } from './AddFeedback';

interface PropTypes {
  application: Application;
  currentUser: User;
  feedbackFFEnabled: boolean;
  jobHiringMemberRole?: JobHiringMemberRole;
  createScorecardPermitted: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication?: () => void;
}

function addFeedbackEnabled(props: PropTypes): boolean {
  const availableOptions = [props.createScorecardPermitted];

  return props.feedbackFFEnabled && availableOptions.some((option) => option);
}

export function FeedbackTab(props: PropTypes) {
  const [filters, setFilters] = useState<FeedbackFilters>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <div className='d-flex'>
      <div className='d-flex flex-column gap-3 w-100'>
        <div className='d-flex justify-content-between'>
          <CandidatePageSubHeader text='Feedback' />
          {addFeedbackEnabled(props) && (
            <AddFeedback
              application={props.application}
              isLoading={isLoading}
              reloadApplication={props.reloadApplication}
            />
          )}
        </div>
        <Row className='flex-nowrap'>
          {props.feedbackFFEnabled && (
            <Col xs='3'>
              <Filters
                application={props.application}
                isLoading={isLoading}
                onSearch={setFilters}
              />
            </Col>
          )}
          <Col>
            <FeedbackList
              isLoading={isLoading}
              filters={filters}
              currentUser={props.currentUser}
              applicationId={props.application.id}
              viewAllScorecards={props.application.viewAllScorecards}
              setIsLoading={setIsLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
