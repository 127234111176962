import React from 'react';
import { AttachmentInput } from '../../../CandidatePage/CandidateActions/SendEmailModal/EmailModalBody/EmailFooter/AttachmentInput';
import { FileError } from 'use-file-picker';
import { AttachmentInputList } from '../../../CandidatePage/CandidateActions/SendEmailModal/EmailModalBody/EmailFooter/AttachmentInputList';
import { FieldArrayWithId } from 'react-hook-form';
import { UpsertEmailTemplateDto } from '../UpsertEmailTemplateDto';
import { MAX_FILE_SIZE_MB, MAX_FILES } from '..';

interface PropTypes {
  fields: FieldArrayWithId<UpsertEmailTemplateDto, 'attachments', 'id'>[];
  errors: FileError[];
  onRemoveField: (index: number) => void;
  openFileSelector: () => void;
}

export function Footer(props: PropTypes) {
  return (
    <>
      <AttachmentInputList
        attachments={props.fields}
        removeFileByIndex={props.onRemoveField}
      />
      <AttachmentInput
        classNames='mt-4'
        filesLength={props.fields.length}
        errors={props.errors}
        maxFiles={MAX_FILES}
        maxFileSizeMb={MAX_FILE_SIZE_MB}
        openFileSelector={props.openFileSelector}
      />
    </>
  );
}
