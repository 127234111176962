import React from 'react';
import { FileError } from 'use-file-picker';
import { AttachmentInputErrorMessages } from '../../../../../../../components/SingleAttachmentInputFormField/AttachmentInputErrorMessages';
import { IconButton } from '../../../../../../../components/IconButton';
import classNames from 'classnames';
import { MAX_EMAIL_FILES_SIZE_MB } from '../../../../../../../utils/emails';

interface PropTypes {
  errors: FileError[];
  maxFiles: number;
  maxFileSizeMb: number;
  filesLength?: number;
  classNames?: string;
  openFileSelector: () => void;
}

export function AttachmentInput(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex gap-2 align-items-center',
        props.classNames,
      )}
    >
      <AttachmentInputErrorMessages
        errors={props.errors}
        maxFileSizeMb={props.maxFileSizeMb}
        maxFiles={props.maxFiles}
        maxFilesSumSizeMb={MAX_EMAIL_FILES_SIZE_MB}
      />
      <IconButton
        buttonText='Attach File'
        color='borderless'
        iconName={'bi-paperclip'}
        disabled={(props.filesLength || 0) >= props.maxFiles}
        onClick={props.openFileSelector}
      />
      <small className='fw-normal text-dark-200'>
        {`Max file size: ${props.maxFileSizeMb}MB / Total size: ${MAX_EMAIL_FILES_SIZE_MB}MB / Max file attachments: ${props.maxFiles}`}
      </small>
    </div>
  );
}
