import React, { useEffect, useState } from 'react';
import { ListScorecard } from '../../../../entities/applicant_tracking/Scorecard';
import { ScorecardService } from '../../../../services/v1/applicant_tracking/ScorecardService';
import { ScorecardListPanel } from './ScorecardListPanel';
import { User } from '../../../../entities/User';
import { EmptyScorecards } from './EmptyStates/EmptyScorecards';
import { LoadingState } from './LoadingState';
import { FeedbackFilters } from '../Filters';
import { SelectOption } from '../../../../components/Select';

interface PropTypes {
  applicationId: number;
  currentUser: User;
  isLoading: boolean;
  filters: FeedbackFilters;
  viewAllScorecards: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

function mapSelectedIds(options: SelectOption[]) {
  return options.map((option) => parseInt(option.value));
}

async function loadScorecards(applicationId: number, filters: FeedbackFilters) {
  return await ScorecardService.list({
    applicationId: applicationId,
    status: filters?.status,
    jobStageIds: mapSelectedIds(filters?.jobStages || []),
    hiringMemberIds: mapSelectedIds(filters?.hiringMembers || []),
    expand: [
      'user',
      'job_stage',
      'survey',
      'survey.sections',
      'survey.sections.questions',
      'survey.answers',
    ],
  });
}

async function upsertApplicationScorecards(applicationId: number) {
  return await ScorecardService.batchCreate({
    applicationId: applicationId,
  });
}

export function FeedbackList(props: PropTypes) {
  const [listScorecards, setListScorecards] = useState<ListScorecard>(null);
  const [isUpsertDone, setIsUpsertDone] = useState(false);

  useEffect(() => {
    upsertApplicationScorecards(props.applicationId).finally(() =>
      setIsUpsertDone(true),
    );
  }, []);

  useEffect(() => {
    props.setIsLoading(true);
    if (!isUpsertDone) return;

    loadScorecards(props.applicationId, props.filters)
      .then((scorecards) => setListScorecards(scorecards))
      .finally(() => props.setIsLoading(false));
  }, [props.filters, isUpsertDone]);

  if (props.isLoading) return <LoadingState />;

  return (
    <>
      {!listScorecards || listScorecards?.entriesCount === 0 ? (
        <EmptyScorecards filters={props.filters} />
      ) : (
        <ScorecardListPanel
          scorecards={listScorecards?.scorecards || []}
          currentUser={props.currentUser}
          viewAllScorecards={props.viewAllScorecards}
        />
      )}
    </>
  );
}
