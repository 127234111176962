import classNames from 'classnames';
import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FormFieldHeader } from '../FormFieldHeader';

interface PropTypes {
  type: string;
  registerReturn?: UseFormRegisterReturn;
  setValueOnChange?: (value: string) => void;
  error?: string;
  fieldName?: string;
  placeholder?: string;
  isRequired?: boolean;
  hideRequireIndicator?: boolean;
  classNames?: string;
  headerClassNames?: string;
  errorClassNames?: string;
  value?: string;
  disabled?: boolean;
  hideHeader?: boolean;
  maxLength?: number;
  minimumCharacters?: number;
  fontSize?: string;
  defaultValue?: string | number | readonly string[];
  showMaxLengthCounter?: boolean;
  showMaxLengthText?: boolean;
  invalidInput?: boolean;
}

export function InputFormField(props: PropTypes) {
  const hideHeader = props.hideHeader ?? false;
  const [textCount, setTextCount] = useState(props.value?.length || 0);

  return (
    <>
      {!hideHeader && (
        <FormFieldHeader
          fieldName={props.fieldName}
          isRequired={props.isRequired}
          hideRequireIndicator={props.hideRequireIndicator}
          classNames={props.headerClassNames}
          maximumCharacters={props.showMaxLengthText && props.maxLength}
          minimumCharacters={props.minimumCharacters}
          fontSize={props.fontSize}
        />
      )}
      <input
        className={classNames(
          'form-control',
          props.invalidInput && 'border-danger',
          props.classNames,
        )}
        defaultValue={props.defaultValue}
        maxLength={props.maxLength}
        type={props.type}
        placeholder={props.placeholder}
        {...props.registerReturn}
        onChange={(e) => {
          setTextCount(e.target.value.length);
          props.registerReturn?.onChange(e);
          props.setValueOnChange && props.setValueOnChange(e.target.value);
        }}
        disabled={props.disabled}
        value={props.value}
      />
      {props.showMaxLengthCounter && props.maxLength && (
        <small className='mt-2 d-flex justify-content-end text-dark-200'>
          {textCount}/{props.maxLength}
        </small>
      )}
      {props.error && (
        <div
          className={classNames(
            'text-danger-500 mt-2 fs-6',
            props.errorClassNames,
          )}
        >
          {props.error}
        </div>
      )}
    </>
  );
}
