import React from 'react';
import { ScorecardBlock } from './ScorecardBlock';
import { getQueryParam } from '../../../../../utils/url';
import { User } from '../../../../../entities/User';
import { Scorecard } from '../../../../../entities/applicant_tracking/Scorecard';

interface PropTypes {
  scorecards: Scorecard[];
  currentUser: User;
  viewAllScorecards: boolean;
}

function showScorecardId(): number {
  return parseInt(getQueryParam('show_scorecard_id'));
}

function bringOpenedScorecardToTop(scorecards: Scorecard[]): Scorecard[] {
  const openedScorecardId = showScorecardId();

  if (!openedScorecardId) return scorecards;

  const indexOfOpenScorecard = scorecards.findIndex(
    (scorecard) => scorecard.id === openedScorecardId,
  );

  if (indexOfOpenScorecard !== -1) {
    const elementToMove = scorecards.splice(indexOfOpenScorecard, 1)[0];
    scorecards.unshift(elementToMove);
  }

  return scorecards;
}

function statusSorting(a: Scorecard, b: Scorecard): number {
  if (a.survey?.status === 'completed' && b.survey?.status === 'pending') {
    return -1; // a comes before b
  } else if (
    a.survey?.status === 'pending' &&
    b.survey?.status === 'completed'
  ) {
    return 1; // b comes before a
  } else {
    return 0; // order remains the same
  }
}

export function ScorecardListPanel(props: PropTypes) {
  const sortedScorecards = bringOpenedScorecardToTop(
    props.scorecards.slice().sort(statusSorting),
  );

  return (
    <>
      {sortedScorecards.map((scorecard, index) => (
        <ScorecardBlock
          key={index}
          scorecard={scorecard}
          opened={scorecard.id === showScorecardId()}
          currentUser={props.currentUser}
          viewAllScorecards={props.viewAllScorecards}
        />
      ))}
      <div className='fs-5 text-dark-200'>
        Showing 1 - {sortedScorecards.length} of {sortedScorecards.length}{' '}
        Scorecards
      </div>
    </>
  );
}
