import snakecaseKeys from 'snakecase-keys';

export function buildBulkScheduleInterviewUrl(
  applicationIds: number[],
  jobId: number,
  interviewStageId: number,
) {
  const baseUrl = '/applicant_tracking/interview_candidate_users/bulk_new';

  const encodedParams = btoa(JSON.stringify(snakecaseKeys({ applicationIds })));

  return (
    baseUrl +
    `?job_id=${jobId}&interview_stage_id=${interviewStageId}#${encodedParams}`
  );
}

export function getApplicationIdsToBulkSchedule(): number[] {
  const urlHash = window.location.hash;

  if (!urlHash) {
    return [];
  }

  const json = JSON.parse(atob(urlHash.slice(1)));

  return json['application_ids'];
}
