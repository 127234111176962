import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card';
import { FormErrorMessage } from '../../../../components/FormErrorMessage';
import { useForm } from 'react-hook-form';
import { OfferLetterTemplateService } from '../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { UpsertOfferLetterTemplateDto } from '../components/UpsertEmailTemplateDto';
import { set } from '../../../../utils/localArchive';
import { OfferLetterTemplate } from '../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { Body } from '../components/Body';

export const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/offer_letter_templates';

interface PropTypes {
  id: number;
}

interface UIPropTypes {
  offerLetterTemplate: OfferLetterTemplate;
}

function getInitOfferLetterTemplate(
  offerLetterTemplate: OfferLetterTemplate,
): UpsertOfferLetterTemplateDto {
  return {
    id: offerLetterTemplate?.id,
    name: offerLetterTemplate?.name,
    offerLetterTemplateReferenceId:
      offerLetterTemplate?.offerLetterTemplateReferenceId,
    template: offerLetterTemplate?.template,
    templateHtml: offerLetterTemplate?.templateHtml,
  };
}

export default function EditOfferLetterTemplatePage(props: PropTypes) {
  const [offerLetterTemplate, setOfferLetterTemplate] =
    useState<OfferLetterTemplate>(null);

  useEffect(() => {
    (async () => {
      setOfferLetterTemplate(await OfferLetterTemplateService.show(props.id));
    })();
  }, [props.id]);

  if (offerLetterTemplate == null) {
    return <LoadingSpinner />;
  }

  return <OfferLetterTemplateUI offerLetterTemplate={offerLetterTemplate} />;
}

function OfferLetterTemplateUI(props: UIPropTypes) {
  const [html, setHtml] = useState<string>(
    props.offerLetterTemplate.templateHtml,
  );

  const {
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    handleSubmit,
    register,
    setError,
  } = useForm<UpsertOfferLetterTemplateDto>({
    mode: 'onChange',
    defaultValues: getInitOfferLetterTemplate(props.offerLetterTemplate),
  });

  const onSubmit = handleSubmit(
    async (
      data: UpsertOfferLetterTemplateDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      try {
        await OfferLetterTemplateService.update(props.offerLetterTemplate.id, {
          ...data,
          templateHtml: html,
        });

        window.location.href = REDIRECT_URI;
      } catch (e: unknown) {
        handleFormErrors(e, setError);
      }
    },
  );

  function handlePreview() {
    set('offer_letter_template', { ...getValues(), templateHtml: html });

    window.open(
      '/applicant_tracking/offer_letter_templates/preview',
      '_blank',
      'noopener,noreferrer',
    );
  }

  return (
    <div className='container'>
      {errors.name?.message && (
        <FormErrorMessage
          error={errors}
          errorMessage={{
            title: `${errors.name?.message}`,
            detail: 'Please change the offer letter template name',
          }}
        />
      )}
      <PageHeader
        title={props.offerLetterTemplate.name}
        backUrl={REDIRECT_URI}
      />
      <Card>
        <Body
          control={control}
          errors={errors}
          isValid={isValid}
          onCancel={() => (window.location.href = REDIRECT_URI)}
          onPreview={handlePreview}
          getValues={getValues}
          setValue={setValue}
          onSubmit={onSubmit}
          register={register}
          setHtml={setHtml}
        />
      </Card>
    </div>
  );
}
