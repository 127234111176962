import React, { useState } from 'react';
import { Application } from '../../../../entities/Application';
import { Dropdown } from '../../../../components/Dropdown';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { PropTypes as ActionPropTypes } from '../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { AddScorecardModal } from './AddScorecardModal';

interface PropTypes {
  application: Application;
  isLoading: boolean;
  reloadApplication: () => void;
}

function addScorecard(action: () => void): ActionPropTypes {
  return {
    action: () => action(),
    buttonChild: (
      <span className='px-2h text-info'>Fill in Ad-hoc Scorecard</span>
    ),
  };
}

export function AddFeedback(props: PropTypes) {
  const menuActions = [addScorecard(() => setScorecardModalOpen(true))];
  const [scorecardModalOpen, setScorecardModalOpen] = useState(false);

  return (
    <div>
      <Dropdown
        buttonIcon={{
          name: 'bi-plus-circle',
          className: props.isLoading && 'disabled',
        }}
        buttonText='Add Feedback'
        buttonClassName={classNames(
          'bg-blue text-white px-2h',
          styles['add-feedback-button'],
        )}
        menuPlacementClass='dropdown-menu-end'
        menuActions={menuActions}
      />
      <AddScorecardModal
        application={props.application}
        isOpen={scorecardModalOpen}
        onClose={() => setScorecardModalOpen(false)}
      />
    </div>
  );
}
